<template></template>
<script>
export default {
    // Data should be an array with objects that countain these properties:
    // - name
    // - answer
    // example: [{name: 'question 1', answer: 'answer 1'}, {name: 'question 2', answer: 'answer 2'}, {name: 'question 3', answer: 'answer 3'}]
    props: ['data'],

    computed: {
        mapQuestons() {
            return this.data
                .map(question => {
                    // check if properties exist
                    if (question.hasOwnProperty('name') && question.hasOwnProperty('answer')) {
                        // build the FAQ structured data type
                        return {
                            '@type': 'Question',
                            name: question.name,
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: question.answer.replace(/<[^>]*>?/gm, ''),
                            },
                        };
                    }
                })
                .filter(item => item);
        },
    },

    head() {
        if (this.data.length > 0) {
            // create root properties for the FAQ structured data type
            const structuredDataObject = {
                '@context': 'https://schema.org',
                '@type': 'FAQPage',
            };

            // don't return if questions length is 0
            if (this.mapQuestons.length > 0) {
                // filter nul values from mapped result and put them in mainentity property
                structuredDataObject.mainEntity = this.mapQuestons;

                return {
                    script: [{ type: 'application/ld+json', json: structuredDataObject, body: true }],
                };
            }
        }
    },
};
</script>
